const SCHEDULED = 'scheduled'
const DECLINED = 'declined'
const DONE = 'done'
const CANCELED = 'canceled'
const PENDING = 'pending'

module.exports = {
  SCHEDULED,
  DECLINED,
  DONE,
  CANCELED,
  PENDING
}
